body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  box-shadow: 2px 2px 5px grey;
}

.button:active {
  transform: translateY(3px);
}

.boxShadow {
  box-shadow: 5px 5px 10px grey;
}

.roundedBorder {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.thumbnail:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.react-logo {
  height: 6vmin;
  pointer-events: none;
}

.spring-logo {
  height: 5vmin;
  pointer-events: none;
}

.spring-boot-logo {
  height: 8vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .react-logo {
    animation: react-logo-spin infinite 5s linear;
  }
}

@keyframes react-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-logo-drop-shadow {
  filter: drop-shadow(1px 1px 1px rgb(0,58,91));
}

.spring-logo-drop-shadow {
  filter: drop-shadow(1px 1px 1px rgb(19,88,0));
}


